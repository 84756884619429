import {NavbarLogo} from '../../utils/allImgs'

export const Logo = NavbarLogo;

export const MenuInfo = [
	{
		path:"#about",
		nameLink:'About'
	},
	{
		path:"#ecosystem",
		nameLink:'Ecosystem'
	},
	{
		path:"#roadmap",
		nameLink:'Roadmap'
	},
	{
		path:"https://dailybux.io",
		nameLink:'Product',
		external: true,
	},
	{
		path:"https://docs.aigo.network",
		nameLink:'Docs',
		external: true,
	}
]
