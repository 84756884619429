import SectionHeading from '../../components/SectionHeading';
import SecImageArea from './SecImageArea';
import AigoNetworkImg from './SecImageArea/aigo_network.gif';

const SecJoinTheEcosystem = () => {
  
  return (
    <section className="join-the-ecosystem section-before" id="ecosystem">
      <div className="container">
        <SectionHeading
          title={"Join the ecosystem\nTransform the urban mobility"}
          text={"We develop an interconnected ecosystem to enable efficient urban mobility.\n Data providers and verifiers aggregate mobility data, getting rewarded through a singular token system,\n and data buyers benefit from a decentralized mobility data marketplace."}
          divider={true}
        />
        <SecImageArea img={AigoNetworkImg} />
      </div>
    </section>
  );
};

export default SecJoinTheEcosystem;
