import {
    HomePartners1,
    HomePartners2,
    HomePartners3,
    HomePartners4,
    HomePartners5,
    HomePartners6,

} from '../../utils/allImgs'


export const timelineInfo = [
    {
        timelineClass:"block block-left",
        title:'2024 Q2',
        description: ['Launch of Dailybux service in South Korea', 'Start of the Vietnam City Manager Program', 'Concurrent marketing in South Korea and Southeast Asia (SEA)', 'Node sale initiative']
    },
    {
        timelineClass:"block block-right mt-30",
        title:'2024 Q3',
        description: ['Launch of Dailybux service in four Southeast Asian countries', 'Expansion of the SEA City Manager Program', 'Launch of AiGO Staking', 'Start of Data Verifier activities', 'Launch of the AiGO Data Marketplace (Beta)'],
    },
    {
        timelineClass:"block block-left mt-30",
        title:'2024 Q4',
        description: ['Expansion of Dailybux service to multiple cities in Korea and SEA', 'Accumulation of 10K refined mobility data sets within the AiGO Network', 'First reward distribution for AiGO Staking', 'Launch of the AiGO Data Marketplace (Official)', 'Partnerships with Korean mobility platforms']
    },
    {
        timelineClass:"block block-right mt-30",
        title:'2025 Q1',
        description: ['Release of Dailybux service v.2', 'Expansion of the Data Verifier community', 'Updates and increased transactions on the Data Marketplace', 'Launch of the AiGO Data Marketplace (Official)', 'Partnerships with SEA mobility platforms and AI ventures']
        
    }
]

export const PartnersData = [
    {img:HomePartners1},
    {img:HomePartners2},
    {img:HomePartners3},
    {img:HomePartners4},
    {img:HomePartners5},
    {img:HomePartners6},
]
