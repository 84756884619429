import React , {useEffect} from "react";
// import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";
import { Switch, Route } from "react-router-dom";
import Aos from 'aos'
import ogImage from './assets/img/core-img/open-graph.jpg';
import Home from './pages/Home';
// import { publicConfigs } from './utils/publicConfigs';

import { TITLE, DESCRIPTION, DOMAIN_URL, PROFILES } from './utils/constants';

// TODO: delete external css files and use packages or local css files
import 'aos/dist/aos.css';
import './assets/css/General.css';
import './assets/css/bootstrap.min.css';
import './assets/css/responsive.css';

import 'bootstrap/dist/js/bootstrap.bundle.min';

// const GA_TRACKING_ID = publicConfigs.REACT_APP_GOOGLE_ANALYTICS_KEY;

const App = () => {

  useEffect(() => {
    Aos.init({
      duration: 1000
      })
  },[])
  
  useEffect(() => {
    // ReactGA.initialize(GA_TRACKING_ID);
  }, []);

  return (
    	<div className="App">
        <Helmet>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <title>
            { TITLE }
          </title>
          <meta property='og:type' content='website' />
          <meta property='og:title' content={TITLE} />
          <meta property='og:description' content={DESCRIPTION} />
          <meta property='og:image' content={ogImage} />
          <meta property='og:url' content={DOMAIN_URL} />
          <meta property='og:site_name' content={TITLE} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:title' content={TITLE} />
          <meta name='twitter:description' content={DESCRIPTION} />
          <meta name='twitter:image' content={ogImage} />
          <meta name='twitter:site' content={DOMAIN_URL} />
          <meta name='twitter:creator' content={PROFILES.twitter.username} />
          
          
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha256-eZrrJcwDc/3uDhsdt61sL2oOBY362qM3lon1gyExkL0=" crossorigin="anonymous" />
        </Helmet>
  			<Switch>
          <Route path="/" exact component={Home} />
  			</Switch>
	    </div>
  );
}

export default App;
