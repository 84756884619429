import SecWelcomeContent from './SecWelcomeContent'

const SecWelcomeArea = () => {

  return (
    <section className="welcome_area clearfix dzsparallaxer fullwidth" data-options={{direction: "normal"}} id="about">
        <div className="divimage dzsparallaxer--target Home1WelcomeAreaIMG"></div>
        <div className="hero-content">

            <div className="container h-100">
                <div className="row align-items-center h-100">
                    <div className="col-12 align-items-center justify-content-lg-start col-lg-6 col-md-12">
                        <SecWelcomeContent />
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
}

export default SecWelcomeArea;
