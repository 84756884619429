import {useEffect} from "react"
import {loader} from '../../utils'
import { Helmet } from "react-helmet";
import { TITLE, DESCRIPTION } from '../../utils/constants';

function PreloaderContainer(){

	useEffect(() => {
		loader()
	},[])

	return(
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>
				<meta name="robots" content="noindex, follow"/>
				<meta
					name="description"
					content={DESCRIPTION}
				/>
			
			</Helmet>
			<div id="preloader">
				<div className="preload-content">
					<div id="dream-load"></div>
				</div>
			</div>
		</>
	)
}

export default PreloaderContainer
