import SectionHeading from '../../components/SectionHeading'
import Timeline from './Timeline'

const SecOurRoadmap = ({data}) => {

  return (
    <section className="roadmap" id="roadmap">
        <SectionHeading
            text='A new era is upon us'
            title='Roadmap'
            reverse={true}
            divider={false}
        />
        <div className="container">
          <div className="row">
            <div className="timeline-split">
              <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="timeline section-box-margin">
                      {data && data.map((item , key) => (
                          <Timeline
                            key={key}
                            timelineClass={item.timelineClass}
                            title={item.title}
                            description={item.description}
                          />
                        ))}
                      <div className="circle"></div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  );
}

export default SecOurRoadmap;
