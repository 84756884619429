import SectionHeading from '../../components/SectionHeading';

const SecMission = () => {
  
  return (
    <section className="our-mission-area section-before section-padding-100">
      <div className="container">
        <SectionHeading
          title="Our Mission"
          text={"AiGO Network’s mission is to build worldwide mobility data ecosystem that benefits all.\n We aim to build a global network that not only moves people more efficiently \nbut also rewards every participant."}
          divider={true}
        />
      </div>
    </section>
  );
};

export default SecMission;
