import TwitterLogo from '../../assets/img/core-img/x-twitter-logo-white.svg';
import TelegramLogo from '../../assets/img/core-img/telegram-logo-white.svg';
const SecVerticalSocial = () => {
  
  return (
    <div className="vertical-social">
      <ul className="flex-column h-100 align-items-center">
        <li>
          <a href="https://twitter.com/AIGO_Network" target="_blank" rel="noopener noreferrer">
            <img
              src={TwitterLogo}
              alt="X(Twitter)"
            />
          </a>
        </li>
        <li>
          <a href="https://t.me/aigo_network" target="_blank" rel="noopener noreferrer">
            <img
              src={TelegramLogo}
              alt="Telegram"
            />
          </a>
        </li>;
      </ul>
    </div>
  );
};

export default SecVerticalSocial;
