import TwitterLogo
  from '../../../../src/assets/img/core-img/x-twitter-logo-black.svg';
import TelegramLogo
  from '../../../../src/assets/img/core-img/telegram-logo-black.svg';

const SecIco = ({logo}) => {
  return (
    <div className="col-12 col-md-6">
      <div className="footer-copywrite-info">
        {/* Copywrite */}
        <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
          <div className="footer-logo">
            <img src={logo} alt="logo"/>
          </div>
          <p>
            {'Shape the future of urban mobility \nthrough decentralized network.'}
          </p>
          <p>
            © 2024 AiGO.network. All rights reserved.
          </p>
        </div>
        {/* Social Icon */}
        <div className="footer-social-info wow fadeInUp" data-wow-delay="0.4s">
          <a href="https://twitter.com/AIGO_Network" target="_blank" rel="noopener noreferrer">
            <img
              src={TwitterLogo}
              alt="X(Twitter)"
            />
          </a>
          <a href="https://t.me/aigo_network" target="_blank" rel="noopener noreferrer">
            <img
              src={TelegramLogo}
              alt="Telegram"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecIco;
