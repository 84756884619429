import {useEffect} from 'react'

import {Logo} from '../../data/data-layout/data-Header.js';
import {MenuInfo} from '../../data/data-layout/data-Header.js';

import {Addshrink , addActiveClass , OpenMenu , moveSmooth} from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
      Addshrink()
  },[])

  useEffect(() => {
      OpenMenu()
  },[])

  useEffect(() => {
      moveSmooth()
  },[])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="#"><img src={Logo} alt="logo" /></a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                    <span />
                    <span />
                    <span />
                </span>
              </div>
              <div className="classy-menu">
                <div className="classycloseIcon">
                  <div className="cross-wrap" onClick={addActiveClass}>
                      <span className="top" />
                      <span className="bottom" />
                  </div>
                </div>
                <div className="classynav">
                  <ul>
                    {MenuInfo.map((item, index) => (
                      item.external ? (
                        <li key={index}>
                          <a href={item.path} target="_blank" rel="noopener noreferrer">{item.nameLink}</a>
                        </li>
                      ) : (
                        <li key={index}>
                          <a onClick={() => moveSmooth(item.path)} href={item.path}>{item.nameLink}</a>
                        </li>
                      )
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
