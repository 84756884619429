const SecImgArea = ({img}) => {

  return (
    <div className="img-area col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12" data-aos="fade-up">
        <div className="network-img-area">
            <img src={img} alt="" />
        </div>
    </div>
  );
}

export default SecImgArea;
