const Timeline = ({timelineClass, title, description}) => {
  
  return (
    <div className={timelineClass}>
      <h3>{title}</h3>
      {description && description.map((item, key) => (
        <p key={key}>• {item}</p>
      ))}
    </div>
  );
};

export default Timeline;
