function SecWelcomeContent() {
  return (
    <div className="welcome-content h-100 text-lg-left text-center">
      <h1 style={{color: '#8CE3FF'}}>Mobility Data Network that Benefits
        All</h1>
      <p>
        AiGO Network transforms daily data into decentralized networks that
        transcend mediums and platforms, shaping the future of urban mobility.
      </p>
      <div className="dream-btn-group row">
        <div className="col-12 col-lg-auto mb-3 mb-lg-0">
          <a href="https://youtu.be/A2bUjtxyiVU" className="btn dream-btn w-100"
             target="_blank" rel="noopener noreferrer">Watch Video</a>
        </div>
        <div className="col-12 col-lg-auto">
          <a href="https://twitter.com/aigo_network" target="_blank"
             rel="noopener noreferrer" className="btn dream-btn w-100">
            Connect with Us
          </a>
        </div>
      </div>
    </div>
  );
}

export default SecWelcomeContent;
