import '../layouts/style/Home.scss'

import {
  timelineInfo,
  PartnersData
} from '../data/data-containers/data-HomeDemo1.js';

import Header from "../layouts/Header"
import Footer from '../layouts/FooterPages'

import SecWelcomeArea from '../layouts/SecWelcomeArea'

import SecVerticalSocial from '../layouts/SecVerticalSocial'
import SecMission from '../layouts/SecMission'
import SecJoinTheEcosystem from '../layouts/SecJoinTheEcosystem';
import SecOurRoadmap from '../layouts/SecOurRoadmap'
import SecPartners from '../layouts/SecPartners'


const Home = () => {

  return (
    <>
      <Header />
      <div className="HomeDemo1">
        <SecWelcomeArea />
        <SecVerticalSocial />
        <SecMission />
        <SecJoinTheEcosystem />
        <div className="clearfix" />
        <div className="clearfix" />
        <SecOurRoadmap data={timelineInfo} />
        <SecPartners data={PartnersData} />
      </div>
      <Footer />
    </>
  );
}

export default Home;

