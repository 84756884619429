import Divider from '../../../src/assets/img/core-img/divider-dimond.svg';

const SectionHeading = ({title, text, reverse, divider}) => {
  
  return (
    <div className="section-heading text-center">
      {divider &&
        <div className="justify-content-center" data-aos="fade-up"
             data-aos-delay="200">
          <img src={Divider} alt="divider"/>
        </div>}

      
      {!reverse ?
        <>
          <h2 data-aos="fade-up" data-aos-delay="300">{title}</h2>
          <p data-aos="fade-up" data-aos-delay="400">{text}</p></> :
        <>
          <p data-aos="fade-up" data-aos-delay="400">{text}</p>
          <h2 data-aos="fade-up" data-aos-delay="300">{title}</h2>
        </>
      }
    </div>
  );
};

export default SectionHeading;
