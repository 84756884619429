const SecContent = () => {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      {/* Content Info */}
      <div
        className="contact_info_area d-flex flex-column flex-sm-row justify-content-between">
        {/* Content Info */}
        <div className="contact_info wow fadeInUp"
             data-wow-delay="0.2s">
          <h5>NAVIGATE</h5>
          <a href="#about"><p>About</p></a>
          <a href="#ecosystem"><p>Ecosystem</p></a>
          <a href="#roadmap"><p>Roadmap</p></a>
          <a href="https://dailybux.io"><p>Product</p></a>
          <a href="https://docs.aigo.network"><p>Docs</p></a>
        </div>
        {/* Content Info */}
        <div className="contact_info wow fadeInUp"
             data-wow-delay="0.3s">
          <h5>COMMUNITY</h5>
          <a href="https://twitter.com/AIGO_Network" target="_blank" rel="noopener noreferrer">
            <p>Twitter / X</p></a>
          <a href="https://t.me/aigo_network" target="_blank" rel="noopener noreferrer">
            <p>Telegram</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SecContent;
