const Form = () => {
	
	const onSubmit = (event) => {
		event.preventDefault();
		window.open('https://rtaivwtxwxz.typeform.com/to/auUzg7XW', '_blank');
	}
	
    return (
	    <div className="row justify-content-center">
	      <div className="col-12 col-md-10 col-lg-8">
	        <div className="contact_form">
	          <form action="#" method="post" id="main_contact_form" noValidate>
	            <div className="row">
	              <div className="col-12">
	                <div id="success_fail_info" />
	              </div>

	              {/*<div className="col-12" data-aos="fade-up">*/}
	              {/*  <div className="group wow fadeInUp" data-wow-delay="0.4s">*/}
	              {/*    <input type="text" name="subject" id="subject" required />*/}
	              {/*    <span className="highlight" />*/}
	              {/*    <span className="bar" />*/}
	              {/*    <label>Email</label>*/}
	              {/*  </div>*/}
	              {/*</div>*/}
	              <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.6s">
		              <button type="submit" className="btn subscribe-btn" data-aos="fade-up" onClick={onSubmit}>Subscribe</button>
	              </div>
	            </div>
	          </form>
	        </div>
	      </div>
	    </div>
    );
}

export default Form
